import React, { Component } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';
import { AgGridReact } from '@ag-grid-community/react';
import {AllModules} from '@ag-grid-enterprise/all-modules';
import "@ag-grid-enterprise/all-modules/dist/styles/ag-grid.css";
import "@ag-grid-enterprise/all-modules/dist/styles/ag-theme-balham.css";
import Select from 'react-select';
import 'react-datetime/css/react-datetime.css';
import $ from 'jquery';
import 'jquery-ui/ui/core';
import 'jquery-ui/ui/widgets/datepicker';
import RemoveGridRow from './removegridrow';
import CSVFileValidator from 'csv-file-validator';
import { getDDMMYYYY, getDDMMYYYYHHMMSS, getYYYYMMDD,getHyphenDDMMMYYYYHHMM, getHyphenYYYYMMDD, getYYYYMMDDHHMMSS, getHyphenYYYYMMDDHHMMSS } from '../common/utils'; 

import * as Datetime from 'react-datetime';

import Modal from 'react-responsive-modal';
var Constant = require('../common/Constant');

$.datetimepicker.setLocale('en');
var moment = require('moment');
var redirectURL = require('../redirectURL');
const cipher = salt => {
    const textToChars = text => text.split('').map(c => c.charCodeAt(0));
    const byteHex = n => ("0" + Number(n).toString(16)).substr(-2);
    const applySaltToChar = code => textToChars(salt).reduce((a,b) => a ^ b, code);

    return text => text.split('')
        .map(textToChars)
        .map(applySaltToChar)
        .map(byteHex)
        .join('');
}
var encode = cipher("V744c@XBTY!uHWA#v6Fe$GXmx%EXuN&ov(6fkW)x");

export const validRegistrationNumber = new RegExp(/^[a-zA-Z0-9]{1,11}$/);

// export const validRegistrationNumber = new RegExp(/^[A-Z|a-z]{2}[0-9]{1,2}[A-Z|a-z]{1,2}[0-9]{1,4}$/);

export const validAlphaNumericSpace = new RegExp(/^[a-zA-Z0-9._& ]{1,255}$/);
export const validAlphaNumeric = new RegExp(/^[a-zA-Z0-9]{1,50}$/);
export const validPhone = new RegExp(/^[789]\d{10}$/);
export const validCoords = new RegExp(/^[0-9.]{1,255}$/);
export const validNumber = new RegExp(/^[0-9]{1,30}$/);


export default class CreateShipment extends Component{
    constructor(props) {
        super(props);
        this.state= {
            modules : AllModules,
            defaultColDef: {
                sortable: true,
                filter: true,
                editable: true,
            },
            eventAction: null,
            show: false,
            basicTitle:'',
            basicType:"default",
            show1: false,
            basicTitle1:'',
            basicType1:"default",
            loadshow:'show-n',
            overly:'show-n',
            rowData:[],
            rowSelection:'multiple',
      	    rowModelhighchartoptionsType: 'enterprise',
            rowGroupPanelShow: 'always',
            frameworkComponents: {
                RemoveGridRow:RemoveGridRow
			},

            statusBar: {
				statusPanels: [
					{
					statusPanel: "agTotalAndFilteredRowCountComponent",
					align: "left"
					},
					{
					statusPanel: "agTotalRowCountComponent",
					align: "center"
					},
					{ statusPanel: "agFilteredRowCountComponent" },
					{ statusPanel: "agSelectedRowCountComponent" },
					{ statusPanel: "agAggregationComponent" }
				]
            },
            paginationPageSize:1000,
            geofencelist:[],
            geoFenceData:"",
            sideBar: {
				toolPanels: [
					{
					id: "columns",
					labelDefault: "Columns",
					labelKey: "columns",
					iconKey: "columns",
					toolPanel: "agColumnsToolPanel"
					},
					{
					id: "filters",
					labelDefault: "Filters",
					labelKey: "filters",
					iconKey: "filter",
					toolPanel: "agFiltersToolPanel"
					}
				]
            },
            animateRows: true,
            truck_no:"",
            type_of_tracking:"",
            vehicle_type:{"value":"Truck","label":"Truck"},
            shipment_mode:{"value":"ROAD","label":"Road"},
            total_shipment_qty:"",
            driver_name:"",
            driver_mobile_number:"",
            parking_id:"",
            parking_date:"",
            parking_time:"",
            transporter_code:"",
            transporter_name:"",
            device_id:"",
            shipment_id:"",
            customer_address:"",
            customer_name:"",
            consignee_code:"",
            customer_pincode:"",
            invoice_date:"",
            shipment_date:"",
            shipment_time:"",
            mobile_network:"",
            gpsServiceProvider:"",
            invoice_date:"",
            shipment_source:{"value":"","label":"Select"},
            shipment_destination:{"value":"","label":"Select"},
            mobile_operation:"",
            railyards:[],
            shipsrcdestshow:"show-n",
            plants:[],
            plant_code:"",
            mobile_networks:[],
            disable_org : false,
            disable_plant : false,
            all_plants_coordinates:[],
            plants_coordinates:[
                { value: '205-FG', label: 'Alwar' },
                { value: '301-FG', label: 'Aurangabad' },
                { value: '903-A', label: 'Aurangabad Copack - MAHCO' },
                { value: 'ARP-001', label: 'Arunachal Pradesh Copack' },
                { value: '501-FG', label: 'Hyderabad' },
                { value: '904-FG', label: 'Mysore' },
                { value: '904-FG', label: 'Kolkata' },
                { value: '801-FG', label: 'Unnao Copack' },
                { value: '908-FG', label: 'Durg, Chhattisgarh Copack' },
                { value: '230', label: 'Hapur Ware House' }
              ],
            sim_tracking_service_provider: [{ label: "JIO", value: "jio" }, { label: "NON-JIO", value: "non_jio" }],
            tracking_type:[{label:"Sim",value:"sim"},{label:"Gps",value:"gps"}],
            gps_service_provider:[{label:"Enmovil",value:"enmovil"},{label:"Non Enmovil",value:"non-enmovil"}],
            // mobile_networks: [{ label: "Airtel", value: "airtel" }, { label: "Reliance Jio", value: "jio" }, 
            // { label: "BSNL ", value: "bsnl" },{ label: "Vodafone Idea ", value: "vi" }],
            plant_name:"",
            driver_code:"",
            invoice_no:"",
            customers:[],
            allcustomers:[],
            isreadonly:false,
            newcustomer_code:"",
            newcustomer_name:"",
            newcustomer_city:"",
            newcustomer_latitude:"",
            newcustomer_longitude:"",
            open: false,
            curentRow:"",
            errtruckmsg:"",
            errtruck:"show-n",
            errmsg : false ,
            padding: 10,
            shipment_no:"",
            customer_address:"",
            customer_city:"",
            consignment_code:"",
            consignor_code:"",
            startDate:"",
            truck_type:"",
            truck_types:[{label: "OWN", value: "O" },{label: "MARKET", value: "M" }],
            item_quantity:"",
            item_desc:"",
            clr:"",
            check:false ,
            checkForDeviceId:false,
            filterTransporters:[],
            filterTransportersCode:[],
            organization:"",
            filterOrganization:[],
            devicesId:[],
            inputDeviceId:"",
            all_transporters:[],
            bulkorganization:"",
            inbrew_plants : ["999-IBKA","999-IBHU","999-IBHO","999-IBNE","999-IBM","999-IBHA"]
			
        }
        this.changeHandler = this.changeHandler.bind(this);
        this.changeVehicleHandler = this.changeVehicleHandler.bind(this);
        this.changeNumberHandler = this.changeNumberHandler.bind(this);
        this.changeAlphaNumericHandler = this.changeAlphaNumericHandler.bind(this);
        this.changeAlphaNumericNOSpeacialHandler = this.changeAlphaNumericNOSpeacialHandler.bind(this);
        this.changeCoords = this.changeCoords.bind(this);
        this.onLoadCustomers = this.onLoadCustomers.bind(this);
    }
    componentDidMount(){
		loadDateTimeScript();

        if(!["undefined",undefined,null].includes(localStorage.getItem("pc"))){
            if(this.state.inbrew_plants.includes(localStorage.getItem("pc")) || localStorage.getItem("email") == "admin@inbrew.in"){
                console.log("check_here")
                this.setState({
                    organization : {label: 'INBREW', value: 2},
                    disable_org : true,
                    disable_plant : localStorage.getItem("email") !== "admin@inbrew.in"
                })
            }
            else{
                this.setState({
                    organization : {label: 'CARLSBERG', value: 1}
                })
            }
            
        }
         

        redirectURL.post("/carlsberg/railyards")
        .then((response) => {
            var geofences = response.data.geofences;
            this.setState({
                railyards:geofences
            })
        })
            
        redirectURL.post("/carlsberg/getOrganizations")
        .then((resp)=>{
            var filterOrganization = [];
            var organizations = resp.data;
            organizations.map((e) => {
                filterOrganization.push({
                    label:e.org_name,
                    value:e.org_id
                })
            });
            console.log(filterOrganization,"filterOrganization")
            this.setState({filterOrganization:filterOrganization})            
        })
        redirectURL.post("/carlsberg/plants")
        .then((response) => {
            var plants = response.data.plants;
            var plants_coordinates = [];
            if(localStorage.getItem("pc") !=undefined && localStorage.getItem("pc") !="" && localStorage.getItem("pc") !="undefined")
            {
                var filteredData = plants.filter((p)=>p.plant_code == localStorage.getItem("pc"));
                if(filteredData.length > 0)
                {
                    console.log(filteredData,"filteredData-123")
                    this.setState({consignor_code:{"value":filteredData[0].plant_code,"label":filteredData[0].plant_code+" - "+filteredData[0].plant_name}});
                }
            }
            else if(localStorage.getItem("email") == "admin@inbrew.in"){
                plants.filter((p)=>{
                    if(p.org_id == 2){
                        plants_coordinates.push({value:p.plant_code,label:p.plant_code+" - "+p.plant_name})
                    }
                })
            }
            else
            {                
                if(localStorage.getItem("roles").indexOf("SITE_ADMIN") >= 0){
                    plants.map((p)=>plants_coordinates.push({value:p.plant_code,label:p.plant_code+" - "+p.plant_name}));
                }
            }
            this.setState({
                all_plants_coordinates:plants,
                plants_coordinates:plants_coordinates
            })
        })
        this.onLoadCustomers();    
        redirectURL.post("/carlsberg/getTransporters",{dept_code:"SNDG",email:localStorage.getItem('email'),role:localStorage.getItem('roles'),customer_code:localStorage.getItem('customer_code')})
        .then((resp)=>{
            var filterTransporters = [];
            var transporters = resp.data.transporters;
            transporters.map((e) => {
                filterTransporters.push({
                    label:e.transporter_name,
                    value:e.transporter_code
                })
            });
            console.log(filterTransporters,"filterTransporters")
            this.setState({all_transporters:transporters,filterTransporters:filterTransporters})            
        })
        var params={}
        redirectURL.post("/carlsberg/deviceIdsList",params).then((resp)=>{
            var enmovilDevicesId = resp.data 
            console.log(enmovilDevicesId,"1233")
            var deviceIds = [{label:"others",value:"others"}]
            enmovilDevicesId.map((e)=>{
                deviceIds.push({
                    label:e,
                    value:e
                })
            })
            console.log(deviceIds,"devicesId")
            this.setState({devicesId:deviceIds})
        })
    }
    onLoadCustomers(){
        redirectURL.post("/carlsberg/customers")
        .then((response) => {
            var customersarr = response.data.customers;
            var customers = []
            if(customersarr.length > 0)
            {
                customersarr.map((item) => {
                    customers.push({value:item.consignee_code,label:item.consignee_code+" - "+item.consignee_name+" - "+item.consignee_city+" - "+item.pin_code})
                })
            }
            console.log(customers,"customers-data")
            this.setState({
                customers:customers
            })
        })
    }
    closeAlert = async () => {
        await this.setState({
            show: false
        });
        // await window.location.reload();
    }
    closeAlert1 = async () => {
        await this.setState({
            show1: false
        });
        await window.location.reload();
    }
    onGridReady = params => {
		this.gridApi = params.api;
		
	    //console.log("Grid is: ", params);
        this.gridColumnApi = params.columnApi;
        
        this.gridApi.addEventListener('paginationChanged', (e) =>
        {
            //Reset rows selection based on current page
           // this.resetPaginationSelection(this);
        });

        this.gridApi.forEachLeafNode( (node) => {
            console.log("node here ",node)
            node.setSelected(true);
         
        });
        
	};
	onGridState = () =>{
		 console.log(this.gridApi);
		
		 /*Get  Current Columns State and Store in this.colState */
		   this.colState = this.gridColumnApi.getColumnState();

		 /*Get Current RowGroup Columns State and Store in this.rowGroupState */
		   this.rowGroupState = this.gridColumnApi.getRowGroupColumns();

		 /*Get Current Pivot Columns State and Store in this.pivotColumns, 
		  * User should enable the Pivot mode.*/
		   this.pivotColumns = this.gridColumnApi.getPivotColumns();
		 		   
		 /*Get Current Filter State and Store in window.filterState */
		   window.filterState = this.gridApi.getFilterModel();
           this.gridApi.setFilterModel(window.filterState);
        //
    }

    changeHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        this.setState({
            [name]:value
        })
    }
    changeCoords(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            console.log("validCoords.test(value) ", validCoords.test(value))
            if(validCoords.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
            
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    changeVehicleHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(value.length <= 10 && (validRegistrationNumber.test(value) == true))
            {
                this.setState({
                    [name]:value,
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
                if(value.length >= 8){
                    var pr = {
                        truck_no:value
                    }
                    redirectURL.post("/carlsberg/truckdetails",pr)
                    .then((response) => {
                        var records = response.data.truckinfo;
                        if(records.length > 0)
                        {
                            try{
                                if(records[0].info.length > 0)
                                {
                                    var transporter_name = records[0].info[0].transporter_name;
                                }
                                else{
                                    var transporter_name = "";
                                }
                                if(records[0].transporter_code != "NA")
                                {
                                    this.setState({
                                        transporter_code:records[0].transporter_code,
                                        transporter_name:transporter_name,
                                        isreadonly:true,
    
                                    })
                                }
                                
                            }
                            catch(e){}
                        }
                        else{
                            this.setState({
                                isreadonly:false,

                            })
                        }
                    })
                }
                else{
                    
                }
            }
        }
        else{
            this.setState({
                [name]:value,
                // errtruckmsg:"Invalid Truck No",
                // errtruck:"show-m"
            })
        }
        
    }
    onBlurTracking(){
        var trackingType = this.state.type_of_tracking
        console.log(trackingType,"trackingType")
        var trackingType = trackingType.value 
        if(trackingType=="gps"){
            this.setState({check:true})
        }
        else if(trackingType=="sim"){
            this.setState({check:false})
        }

    }
    onBlurDevice(){
        var deviceid = this.state.device_id.value
        console.log(deviceid,"deviceid")
        if(deviceid=="others"){
            this.setState({checkForDeviceId:true})
        }
        else {
            this.setState({checkForDeviceId:false})
        }
    }
    onBlurTruck(){
        var truck = this.state.truck_no;
        if(truck == "")
        {
            this.setState({
                errtruckmsg:"Please enter vehicle no",
                errtruck:"show-m"
            })
        }
        else{
            if(truck.length < 8){
                this.setState({
                    errtruckmsg:"Invalid Truck No",
                    errtruck:"show-m"
                })
            }
            else{
                this.setState({
                    errtruckmsg:"",
                    errtruck:"show-n"
                })
                var params={
                    truck_no:truck
                }
                redirectURL.post("/carlsberg/checktruckno",params)
                .then((response)=>{
                    var row = response.data[0] 
                    if(response.data.length!=0){
                        if(row.latest_gps_packet!="undefined" && row.latest_gps_packet!="" && row.latest_gps_packet!=undefined){
                            var latest_gps_packet = row.latest_gps_packet 
                    
                            var latest_gps = moment.parseZone(new Date(latest_gps_packet)).utcOffset("-05:30")._d;
                            console.log(latest_gps_packet,latest_gps,"latest")
                            const currentTime = new Date(); 
                            console.log(currentTime,"currentTime")
                            const timeDiffMillis = currentTime.getTime() - latest_gps.getTime(); 
                            console.log(timeDiffMillis,"timeDiffMillis")
                            const timeDiffHours = timeDiffMillis / (1000 * 60 * 60);
                            console.log(timeDiffHours,"123")

                            if(parseInt(timeDiffHours) < 6) {
                            this.setState({errtruckmsg : "GPS Available & Working",errtruck:"show-m",clr:"green"}) 
                            } else { 
                                this.setState({errtruckmsg : "GPS Available & Not Working",errtruck:"show-m",clr:"red"}) 
                            }
                        
                        }
                        else
                        {
                            this.setState({errtruckmsg : "GPS Not Available",errtruck:"show-m",clr:"red"}) 
                        }
                    } 
                    else{
                        this.setState({errtruckmsg : "GPS Not Available",errtruck:"show-m",clr:"red"})
                    }
                    
                })

            }
        }

    }
    changeAlphaNumericHandler(event){
        console.log(event.target.name,"431")
        
        var name= event.target.name;
        var value = event.target.value;
        // const re = /^[A-Za-z]+$/;
        // if(value != "")
        // {
        //     if(validAlphaNumericSpace.test(value) == true)
        //     {
                

                this.setState({
                    [name]:value
                })
        //     }
        // }
        // else{
            

        //     this.setState({
        //         [name]:value
        //     })
        // }
        
    }
    changeAlphaNumericNOSpeacialHandler(event){
        var name= event.target.name;
        var value = event.target.value;
        if(value != "")
        {
            if(validAlphaNumeric.test(value) == true)
            {
                this.setState({
                    [name]:value
                })
            }
        }
        else{
            this.setState({
                [name]:value
            })
        }
        
    }
    handlerStartDateTime = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			shipment_time:getYYYYMMDD(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
    handlerStartDateTimeForOrderDate = (event, currentDate, selectedDate) => {
		var d = new Date(event._d);
		
		var startdate = d.getFullYear()+"-"+(d.getMonth() +1)+"-"+d.getDate()+" "+d.getHours()+":"+((d.getMinutes() < 10) ? "0"+d.getMinutes():d.getMinutes())+":"+((d.getSeconds() < 10) ? "0"+d.getSeconds():d.getSeconds());
		
		this.setState({
			startDate:getHyphenYYYYMMDDHHMMSS(d)
		});
		//let value = event.target.value;
		//this.setState({'startDate':value});
	}
        
    changeNumberHandler(event) {
        const name = event.target.name;
        const value = event.target.value;    
        // Regular expression to allow only numbers (no spaces or non-numeric characters)
        if (/^\d*$/.test(value)) {
            this.setState({
                [name]: value
            });
        }
        // If the value contains spaces or non-numeric characters, don't update the state
    }  
    
    createShipmentForm(event){
        event.preventDefault();
        var items=[];
        // console.log("AGGrid data ",items);
        var shipment_date = $("#shipment_date").val();
        var shipment_time = $("#shipment_time").val();
        var invoice_date = $("#invoice_date").val();
        var flag = 0;
        var itmerr=[];
        var err = [];
        var transiterr=[];
        var distanceerr=[];
        var customererr=[];
        if(items.length > 0)
        {
            items.map((item) => {
                if(item.consignment_code != "" && item.delivery_no != "" && item.sales_order != "" && item.item_no != "" && item.item_desc != "" && item.item_quantity != "" && item.consignee_code != "" && item.consignee_name != "" && item.consignee_city != "" && item.lat != "" && item.lng != "" && item.distance_in_km != "")
                {
                    // if(item.transit_time < 0)
                    // {
                    //     transiterr.push(1);
                    //     // err.push(1);
                    // }
                    if(item.distance_in_km < 0)
                    {
                        // err.push(1);
                        distanceerr.push(1);
                    }
                    if(item.consignee_name == Constant.NEW_CUSTOMER)
                    {
                        customererr.push(1);
                    }
                }
                else{
                    err.push(1);
                }
            })
        }
        else{
            itmerr.push(1);
        }
        
        
        {/*if(this.state.transporter_code  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter code can not be empty.",
                basicType:'danger'
            })
        }
        
        if(this.state.transporter_name  == "")
        {
            flag = 1;
            
            this.setState({
                show:true,
                basicTitle:"Transporter Name can not be empty.",
                basicType:'danger'
            })
        }*/}
        

       
        try{
            if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
            {
                if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                {
                    flag = 1;
                    
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Source can not be empty.",
                        basicType:'danger'
                    })
                }
                if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                {
                    flag = 1;
                    this.setState({
                        show:true,
                        basicTitle:"Shipment Destination can not be empty.",
                        basicType:'danger'
                    })
                }
            }
        }
        catch(e){}
        if(flag == 0)
        {
            var pd = [];
            var pd1 = [];
            var pdate = "";
            
            // console.log("pd",pd)
            // console.log("pdate",pdate)

            var sd = [];
            var sd1 = [];
            var shdate = "";

            if(shipment_date != "" && shipment_date != undefined)
            {
                sd = shipment_date.split("/");
                if(sd.length == 3){
                    if(sd[0].length == 4)
                    {
                        shdate = sd[0]+"-"+sd[1]+"-"+sd[2];
                    }
                    else{
                        shdate = sd[1]+"-"+sd[0]+"-"+sd[2];
                    }
                     
                }
                else{
                    sd1 = shipment_date.split("-");
                    if(sd1.length == 3)
                    {
                        shdate = shipment_date;
                    }
                }
                
            }
            var inv_date = ""
            if(invoice_date != "" && invoice_date != undefined)
            {
                sd = invoice_date.split(" ")
                console.log(sd,"sddd")
                var date=sd[0].split("-")
                inv_date = date[2]+"-"+date[1]+"-"+date[0]+" "+sd[1]
               
            }
            var ped = $("#permit_expiry_date").val();
            var permit_expiry_date = ""
            if(ped != "" && ped != undefined)
            {
                sd1 = ped.split(" ")
                console.log(sd1,"sd1")
                var date1=sd1[0].split("-")
                permit_expiry_date = date1[2]+"-"+date1[1]+"-"+date1[0]+" "+sd1[1]
               
            }
            console.log(inv_date,"inv_date")
            console.log(shdate,"shdate")
            var shipment_id = moment.parseZone().format('YYYY-MM-DD HH:mm:ss');
            var shipmentDate = shdate.split("-")
            var sh_date = shipmentDate[2]+"-"+shipmentDate[1]+"-"+shipmentDate[0]
            var date=""
            if(sh_date!="" && sh_date!=undefined && shipment_time!="" && shipment_time!=undefined){
                 date=sh_date+" "+shipment_time+":00"
            }
            var params ={
                org_id:this.state.organization.value,
                org_name:this.state.organization.label,
                truck_no:this.state.truck_no,
                driver_code:this.state.driver_code,
                driver_name:this.state.driver_name,
                transporter_code:this.state.transporter? this.state.transporter.value : "",
                transporter_name: this.state.transporter?  this.state.transporter.label : "",
                shipment_id:shipment_id,
                shipment_time:date,
                // shipment_time:shipment_time,
                sales_order:this.state.invoice_no,
                shipment_mode:this.state.shipment_mode.value,
                plant_code:this.state.plant_code,
                consignee_code: this.state.consignee_code ? this.state.consignee_code.value : "",
                consignment_code:this.state.consignment_code,
                consignor_code: this.state.consignor_code ? this.state.consignor_code.value : "",
                consignment_date:this.state.startDate,
                truck_type:this.state.truck_type,
                item_quantity:this.state.item_quantity,
                item_desc:this.state.item_desc,
                // invoice_date:inv_date,
                invoice_date:date,
                ofs_no:this.state.ofs_no,
                permit_expiry_date:permit_expiry_date,
                email:localStorage.getItem("email"),
                
               
            }
            var device_id = this.state.device_id ?  this.state.device_id.value :""
            console.log(device_id,"device_id")
            if(device_id!="others"){
                params.device_id = this.state.device_id ?  this.state.device_id.value :""
            }
            else{
                console.log(this.state.inputDeviceId,"rupa")
                params.device_id = this.state.inputDeviceId || ""
                params.is_other_device = true 
            }
            var drivernum = this.state.driver_mobile_number 
            var driver_num = parseInt(this.state.driver_mobile_number)
            if (drivernum.length==10 && drivernum!="" && drivernum!=undefined){
                params.driver_mobile_number=this.state.driver_mobile_number 
                this.setState({errmsg:false})
            }
            else{
                this.setState({errmsg:true,driver_mobile_number:""})
            }
            var gpsServiceProvider = this.state.gpsServiceProvider
            if(gpsServiceProvider!="" && gpsServiceProvider!=undefined){
                params.gps_service_provider = gpsServiceProvider.value
            }
            var mobileNetwork = this.state.mobile_network 
            if (mobileNetwork!="" && mobileNetwork!="undefined"){
                params.mobileNetwork = mobileNetwork.value
            }
            console.log(mobileNetwork,"603")
            if(this.state.tracking_type!="" && this.state.tracking_type!=undefined){
                params.tracking_type = this.state.type_of_tracking.value || ""
            }
            console.log("Form params ", params);
            redirectURL.post("/carlsberg/saveshipmentapi",params)
            .then((response) => {
                console.log(response.data);
                if(response.data.outputs[0].status == "Success")
                {
                    this.setState({
                        show1:true,
                        basicTitle1:"Successfully created shipment",
                        basicType1:"success",
                        truck_no:"",
                        vehicle_type:{"value":"Truck","label":"Truck"},
                        shipment_mode:{"value":"ROAD","label":"Road"},
                        driver_code:"",
                        invoice_no:"",
                        driver_name:"",
                        driver_mobile_number:"",
                        parking_id:"",
                        parking_date:"",
                        parking_time:"",
                        transporter_code:"",
                        transporter:"",
                        device_id:"",
                        shipment_id:"",
                        customer_address:"",
                        customer_city:"",
                        consignment_code:"",
                        consignor_code:"",
                        truck_type:"",
                        startDate:"",
                        customer_name:"",
                        consignee_code:"",
                        customer_pincode:"",
                        invoice_date:"",
                        shipment_date:"",
                        shipment_time:"",
                        shipment_source:{"value":"","label":"Select"},
                        shipment_source_name:"",
                        shipment_destination:{"value":"","label":"Select"},
                        shipment_destination_name:"",
                        shipment_items:[],
                        plant_code:"",
                        plant_name:"",
                        shipsrcdestshow:"show-n"

                    })
                    console.log("Success","Success")
                    // this.gridApi.setRowData([]);
                    // $("#parking_date").val("");
                    // $("#parking_time").val("");
                    // $("#shipment_date").val("");
                    // $("#shipment_time").val("");
                    
                }
                else{
                    this.setState({
                        show:true,
                        basicTitle:response.data.outputs[0].message,
                        basicType:"danger"
                    })
                }
            });
        }
        else{
            if(itmerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please add atleast one item.",
                    basicType:'danger'
                })
            }
            else if(distanceerr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Expected Distance (Km) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(transiterr.length > 0){
                this.setState({
                    show:true,
                    basicTitle:"Transit Time (Days) should be greater than zero.",
                    basicType:'danger'
                })
            }
            else if(customererr.length > 0)
            {
                this.setState({
                    show:true,
                    basicTitle:"Please select customer from droppwn list.",
                    basicType:'danger'
                })
            }
            else{
                if(err.length > 0)
                {
                    this.setState({
                        show:true,
                        basicTitle:"Invalid Data.",
                        basicType:'danger'
                    })
                }
            }
            if(this.state.truck_no  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle No can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.vehicle_type.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Vehicle Type can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.shipment_mode.value  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Mode can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_code == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver Code can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.driver_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Driver name can not be empty.",
                    basicType:'danger'
                })
            }
            if(this.state.parking_id  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Parking Entry no can not be empty.",
                    basicType:'danger'
                })
            }
            
            
            if(this.state.transporter_code  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter code can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(this.state.transporter_name  == "")
            {
                this.setState({
                    show:true,
                    basicTitle:"Transporter Name can not be empty.",
                    basicType:'danger'
                })
            }
    
            if(shipment_date  == "" || shipment_date  == "__-__-____")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Date can not be empty.",
                    basicType:'danger'
                })
            }
            
            if(shipment_time  == "" || shipment_time  == "__:__")
            {
                this.setState({
                    show:true,
                    basicTitle:"Shipment Time can not be empty.",
                    basicType:'danger'
                })
            }
            try{
                if(this.state.shipment_mode.value != "" && this.state.shipment_mode.value == Constant.SHIPMENT_MODE_RAIL)
                {
                    if(this.state.shipment_source.value  == "" || this.state.shipment_source.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Source can not be empty.",
                            basicType:'danger'
                        })
                    }
                    if(this.state.shipment_destination.value  == "" || this.state.shipment_destination.value  == "Select")
                    {
                        this.setState({
                            show:true,
                            basicTitle:"Shipment Destination can not be empty.",
                            basicType:'danger'
                        })
                    }
                }
            }
            catch(e){}



        }
        
    }
    
    vehicletypes()
    {
        var items= [];
        items.push({
            value:"Truck",
            label:"Truck"
        },{
            value:"Trailer",
            label:"Trailer"
        })
        return items;
    }
    
    onChangeVehicleType(vehicle_type){
        this.setState({vehicle_type},() => {

        })
    } 
    shipmentsources(){
        var items= [];
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_loading_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        
        return items;
    }
    onChangeSources(shipment_source){
        this.setState({shipment_source},() => {

        })
    }
    shipmentmodes(){
        var items= [];
        items.push({
            value:"ROAD",
            label:"Road"
        },{
            value:"RAIL",
            label:"Rail"
        })
        return items;
    }
    onChangeModeType(shipment_mode){
        this.setState({shipment_mode},() => {
            if(this.state.shipment_mode.value == Constant.SHIPMENT_MODE_ROAD)
            {
                this.setState({
                    shipsrcdestshow:"show-n"
                })
            }
            else{
                this.setState({
                    shipsrcdestshow:"show-m"
                })
            }
        })
    }
    shipmentdestinations(){
        var items= [];
        
        var railyards = this.state.railyards;
        if(railyards.length > 0)
        {
            railyards.map((item) => {
                if(item.rail_delivery_point == 1)
                {
                    items.push({
                        value:item._id,
                        label:item.geofence_name
                    })
                }
            })
        }
        return items;
    }
    onChangeDestination(shipment_destination){
        this.setState({shipment_destination},() => {

        })
    }
    addRow(){
    
        this.gridApi.updateRowData({
          add: [{ 
              consignment_code: '', 
              delivery_no: '', 
              sales_order: '',
              item_no:"", 
              item_desc:"",
              item_quantity:"",
              consignee_code:"",
              consignee_name:"", 
              consignee_city:"",
              lat:"",
              lng:"",
              distance_in_km : "", 
            //   transit_time:"" 
            }]
        });
        // gridOptions.singleClickEdit = true

    }
    
    onCellEditData = async (params) => {
        if(params.colDef.field == "consignee_name")
        {
            if(params.value == Constant.NEW_CUSTOMER)
            {
                // console.log("params: ", params)
                this.setState({
                    open:true,
                    curentRow:params.data
                })
                
            }
            else{
                
                // console.log("params here ", params)
                var selectcustomer = [];
                if(this.state.allcustomers.length > 0){
                   await this.state.allcustomers.map((item) => {
                        if(item.consignee_name == params.value)
                        {
                            selectcustomer.push(item);
                             
                        }
                    })
                }
                try{
                    // console.log("selectcustomer ", selectcustomer)
                    if(selectcustomer.length > 0)
                    {   
                        var coords = JSON.parse(selectcustomer[0].coordinates);
                        // console.log("coords ", coords)
                        params.data.consignee_code = selectcustomer[0].consignee_code;
                        params.data.consignee_name = selectcustomer[0].consignee_name;
                        params.data.consignee_city = selectcustomer[0].consignee_city;
                        params.data.lat = coords.lat;
                        params.data.lng = coords.lng;
                        // console.log("params here ", params)
                        await this.gridApi.updateRowData({ update: [params.data] });
                    }
                    
                }
                catch(e){

                }
                
            }
            
        }

    }
    deleteRow(params){
        let deletedRow = params.data;
        this.gridApi.updateRowData({ remove: [deletedRow] })
    }
    
	onOpenModal = () => {
		this.setState({ open: true });
	 };
	
	 onCloseModal = () => {
        try{
            let deletedRow = this.state.curentRow;
            // console.log("deletedRow ", deletedRow)
            if(deletedRow.consignee_name == Constant.NEW_CUSTOMER)
            {
                deletedRow.consignee_name = "";
                deletedRow.consignee_code = "";
                deletedRow.consignee_city = "";
                deletedRow.lat = "";
                deletedRow.lng = "";
            }
            // console.log("After deletedRow ",deletedRow)
            this.gridApi.updateRowData({ update: [deletedRow] })
        } 
        catch(e){

        }
	   this.setState({ open: false });
	 };

     saveNewCustomerForm(event){
        event.preventDefault();
        var newcustomer_code = this.state.newcustomer_code;
        var newcustomer_name = this.state.newcustomer_name;
        var newcustomer_city = this.state.newcustomer_city;
        var newcustomer_latitude =this.state.newcustomer_latitude;
        var newcustomer_longitude = this.state.newcustomer_longitude;
        var parameters = {
            newcustomer_code:newcustomer_code,
            newcustomer_name:newcustomer_name,
            newcustomer_city:newcustomer_city,
            newcustomer_latitude:newcustomer_latitude,
            newcustomer_longitude:newcustomer_longitude,
        }
        redirectURL.post("/carlsberg/savecustomer",parameters)
        .then((response) => {
            if(response.data.status == "success")
            {
                var row = this.state.curentRow;
                row.consignee_code = this.state.newcustomer_code;
                row.consignee_name = this.state.newcustomer_name;
                row.consignee_city = this.state.newcustomer_city;
                row.lat = this.state.newcustomer_latitude;
                row.lng = this.state.newcustomer_longitude; 
                this.gridApi.updateRowData({ update: [row] })
                
                this.setState({
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
                this.onLoadCustomers();
            }
            else{
                this.setState({
                    show:true,
                    basicTitle:"Failed to add customer",
                    basicType:"danger",
                    open:false,
                    loadshow:"show-n",
                    overly:"show-n"
                })
            }
        })
        
     }
     newFilter = (filterType, selOption) => {
		// console.log(filterType, selOption)
        if(filterType == "device_id")
        {
            if(selOption.value=="others"){
                this.setState({ [filterType]: selOption });
                this.setState({checkForDeviceId:true})
            }
            else {
                this.setState({ [filterType]: selOption });
                this.setState({checkForDeviceId:false})
            }
        }
        else
        {
            console.log([filterType],selOption)
            this.setState({ [filterType]: selOption });
        }
        if(filterType == "organization")
        {
            var org_id = selOption.value;
            var plants_coordinates = []
            var all_plants_coordinates = this.state.all_plants_coordinates;
            if(all_plants_coordinates.length > 0)
            {
                var filteredData = all_plants_coordinates.filter((p)=>p.org_id == org_id);
                if(filteredData.length > 0)
                {
                    console.log(filteredData,"filteredData-123")
                    filteredData.map((p)=>plants_coordinates.push({value:p.plant_code,label:p.plant_code+" - "+p.plant_name}));
                    this.setState({plants_coordinates:plants_coordinates,consignor_code:""});
                }
            }
            var filterTransporters = []
            var all_transporters = this.state.all_transporters;
            if(all_transporters.length > 0)
            {
                if(selOption.value != 2){
                    var transporters = all_transporters.filter((a)=>a.org_id == org_id);
                    if(transporters.length > 0)
                    {
                        transporters.map((e) => {
                            filterTransporters.push({
                                label:e.transporter_name,
                                value:e.transporter_code
                            })
                        });
                    }
                }
                else{
                    all_transporters.map((e) => {
                        filterTransporters.push({
                            label:e.transporter_name,
                            value:e.transporter_code
                        })
                    });
                }
                console.log(filterTransporters,"filterTransporters")
                this.setState({filterTransporters:filterTransporters,transporter:""})
            }
        }
	}
    onClickHide(){
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			sliderTranslatesidebar:'',
			sliderForceCloseTranslate:'',
			sliderRakeTranslate:'',
			bulkslide:'',
			sliderForceDestination:'',
			sliderForceLoadingYard:'',
			sliderBulkForceClose:"",
			bulkdeliverslide:""
		});
		
	}
    formBulkForceCloseHandler(event){
		event.preventDefault();
		var dept_code = this.state.deptcode;
		if(this.state.file != '')
		{
			//console.log("this.state.file ",this.state.file)
			var csvdd = this.state.file;
			
			//console.log("event ",event.target.uploadFile.value);
			
			//console.log('file',formData);
			this.setState({
				loadshow:'show-m'
			});
			var reasonFlag = 0;
			var format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/;
            var csvcontent = this.state.csvcontent;
            var org_id = 1;
            var org_name = "CARLSBERG";
            if(this.state.bulkorganization !=undefined && this.state.bulkorganization !="")
            {
                org_id = this.state.bulkorganization.value;
                org_name = this.state.bulkorganization.label;
            }
			csvcontent.map(function(e,index){
                e.org_id = org_id;
                e.org_name = org_name;
				if(index != 0)
				{
					if(e.reason == "" || e.reason == null || e.reason == undefined)
					{
						reasonFlag = 1;
					}
					if(reasonFlag == 0)
					{
						if(format.test(e.reason)){reasonFlag=1;}
					}
				}
				
			})
			console.log(reasonFlag)
			var rparams = {
				uploadFile:this.state.file,
				userId:localStorage.getItem('userid'),
				csvcontent:csvcontent,
				dept_code : dept_code,
                email:localStorage.getItem("email")
			}
            console.log(rparams,"rparams")
            redirectURL.post("/consignments/uploadRailBulkForceClose", rparams)
            .then(
            (response)=>{
                console.log(response,"3293");
                //console.log(response.data);
                // document.getElementById("upform").reset();
                if(response.data.message == 'success')
                {
                    console.log(response.data.message,"err")
                    this.setState({
                        show: true, 
                        basicType:'success',
                        basicTitle:"Bulk Uploaded Successfully",
                        uploadDivWidth:'0%',
                        sliderTranslate:'',
                        showDiv:'show-n',
                        uploadFile:'',
                        loadshow:'show-n',
                        file:'',
                        sliderBulkForceClose:"",
                        overly:"show-n"
                    });
        
                }						
            }).catch(function(error){
                console.log(error);
            });
		}
	}
    changeBulkForceFileHandler = async (e) => {
		//console.log(e.target.files[0])
		var dt = '';
		// const config = {
		// 	headers: ["ticketNo", "communicationType", "status", "satisfactoryResponse", "comments", "pasteResponseEmail"]
		// }

		const config = {
			headers: [
				{ 
					name: 'truck_no',
					inputName: 'truck_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'transporter_code',
					inputName: 'transporter_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
				{ 
					name: 'transporter_name',
					inputName: 'transporter_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                

                { 
					name: 'driver_name',
					inputName: 'driver_name',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                
                
                { 
					name: 'driver_mobile_number',
					inputName: 'driver_mobile_number',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consignment_code',
					inputName: 'consignment_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'sales_order',
					inputName: 'sales_order',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                
                { 
					name: 'shipment_time',
					inputName: 'shipment_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},

                { 
					name: 'consignee_code',
					inputName: 'consignee_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'consigner_code',
					inputName: 'consigner_code',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'mobileNetwork',
					inputName: 'mobileNetwork',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'invoice_time',
					inputName: 'invoice_time',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'ofs_no',
					inputName: 'ofs_no',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'permit_expiry_date',
					inputName: 'permit_expiry_date',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'tracking_type',
					inputName: 'type_of_tracking',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                { 
					name: 'gps_service_provider',
					inputName: 'gps_service_provider',
					required: true,
					requiredError: function (headerName, rowNumber, columnNumber) {
						return `${headerName} is required in the ${rowNumber} row / ${columnNumber} column`
					}
				},
                
                
			]
		}
		 var data = CSVFileValidator(e.target.files[0], config)
		.then((csvData) => {
			this.setState({
				csvcontent:csvData.data
			});
		})
		.catch(err => {})
			
		var out = new Promise(function(reject, resolve){
			var reader =  new FileReader();
			reader.onload = async function(e) {
				var contents = await e.target.result;
				console.log("contents ", contents )
				resolve(contents);
			};
			var tt =  reader.readAsText(e.target.files[0]);
			console.log("tt ",tt)
		});
		//console.log("Out ", out);
		
		this.setState({
			file:e.target.files[0]
		});
		//console.log("e.target.files[0].type ", e.target.files[0].type);

		if(e.target.files[0].type == 'text/csv' || e.target.files[0].type == 'application/vnd.ms-excel')
		{
		}
		else{
			e.target.value = null;
			this.setState({
				uploadFile:'',
				show: true, 
				basicType:'danger', 
				basicTitle:'Please upload file having extensions .csv only.',
			});
		}
		
	}
	

     onClickHideAll(){
        // try{
        //     let deletedRow = this.state.curentRow;
        //     // console.log("deletedRow ", deletedRow)
        //     if(deletedRow.consignee_name == "New Customer")
        //     {
        //         deletedRow.consignee_name = "";
        //         deletedRow.consignee_code = "";
        //         deletedRow.consignee_city = "";
        //         deletedRow.lat = "";
        //         deletedRow.lng = "";
        //     }
        //     console.log("After deletedRow ",deletedRow)
        //     this.gridApi.updateRowData({ update: [deletedRow] })
        // } 
        // catch(e){

        // }
        
		this.setState({
			loadshow:'show-n',
			overly:'show-n',
			sliderRouteTranslate:'',
			tabsliderTranslate:'',
			bulkslide:'',
		});
		
	}
    onClickBulkUpload()
	{
		this.setState({
			sliderBulkForceClose:'slider-translate',
			overly:"show-m"
		});
	}
    handleMouseOver = () => {
        this.setState({ padding: 10 });
      }
    
      // Reset padding when the cursor moves off of the component
      handleMouseOut = () => {
        this.setState({ padding: 8 });
      }
    
    

    render(){
        
		const { open } = this.state;
        var customerslist = this.state.customers;
        var columnwithDefs  = [
            {
                headerName:"Batch No",
                field:"consignment_code",
                editable:true,
                width:120
            },
            {
                headerName:"Delivery No",
                field:"delivery_no",
                editable:true,
                cellEditor:NumericCellEditor,
                width:140
            },
            {
                headerName:"SO Number",
                field:"sales_order",
                editable:true,
                width:140
            },
            {
                headerName:"Item No",
                field:"item_no",
                width:100,
                editable:true,
            },
            {
                headerName:"Item Desctiption",
                field:"item_desc",
                width:220,
                editable:true,
            },
            {
                headerName:"Quantity",
                field:"item_quantity",
                width:100,
                editable:true,
                cellEditor:NumericCellEditor
              
            },
            {
                headerName:"Customer Name",
                field:"consignee_name",
                width:200,
                editable:true,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: customerslist,
                }
            },
            {
                headerName:"Customer Code",
                field:"consignee_code",
                width:100,
                editable:false,
                // valueGetter:function(params){
                //     try{
                //         if(params.data.customer_code != "")
                //         {
                //             return params.data.customer_code;
                //         }
                //         else{
                //             return "";
                //         }
                //     }
                //     catch(e){
                //         return "";
                //     }
                // }
            },
            {
                headerName:"Customer City",
                field:"consignee_city",
                width:184,
                editable:false,
            },
            {
                headerName:"Customer Latitude",
                field:"lat",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Customer Longitude",
                field:"lng",
                width:100,
                editable:false,
                cellEditor:NumericCellEditor
            },
            {
                headerName:"Expected Distance (KM)",
                field:"distance_in_km",
                width:140,
                editable:true,
                cellEditor:NumericCellEditor
            },
            // {
            //     headerName:"Transit Time (Days)",
            //     field:"transit_time",
            //     width:140,
            //     editable:true,
            //     cellEditor:NumericCellEditor
            // },
            {
                headerName:"",
                field:"",
                width:100,
                editable:false,
                cellRendererSelector:function(params){
                    return { 
                        component: 'RemoveGridRow'
                    };
                },
            }
        ]
        return (
            <div class="container-fluid">          
                <SweetAlert
                    show={this.state.show}
                    type={this.state.basicType}
                    title={this.state.basicTitle}
                    onConfirm={this.closeAlert}
                >
                </SweetAlert>
                <SweetAlert
                    show={this.state.show1}
                    type={this.state.basicType1}
                    title={this.state.basicTitle1}
                    onConfirm={this.closeAlert1}
                >
                </SweetAlert>
                <div className="row">
                    
                
                    <div className="col-xl-12 col-lg-12">
                        <div className="card">
                        
                            <div className="card-header">
                            <div className="col-xl-12 mb-10p">
										
                                        <span className="layoutbtns">
                                            <button className="float-right custom-btn white btn-warning" style={{ marginRight: "10px" }} onClick={this.onClickBulkUpload.bind(this)}  >
                                                Bulk Upload
                                            </button>
                                            {/* <div className="float-right form-group col-xl-2 col-lg-2">
										
										<Select
											className="border-radius-0"
											isMulti={false}
											id="gpsServiceProvider"
                                            placeholder="Gps Service Provider"
											style={{ borderRadius: "0px" }}
											value={this.state.gpsServiceProvider}
											onChange={this.newFilter.bind(this, "gpsServiceProvider")}
											options={this.state.gps_service_provider}
                                            required ={true}
										/>
									</div>		 */}
                                                            
                                        </span>
                                    </div>
                                <h5>
                                    <span>Create Shipment </span>
                                    {/* <a href={"/shipment"} className="btn btn-default pull-right">Back to List</a> */}
                                </h5>
                            </div>
                            <div className="card-body pt-15px">
                             
                            <form method="POST" className="form-theme col-xl-12 col-lg-12" onSubmit={this.createShipmentForm.bind(this)}>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_PARKING}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="form-group col-xl-3 col-lg-2">
                                                <label>Organization </label>
                                                <Select
                                                    className="border-radius-0"
                                                    name="organization"
                                                    isMulti={false}
                                                    id="organization"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.organization}
                                                    onChange={this.newFilter.bind(this, "organization")}
                                                    options={this.state.filterOrganization}
                                                    isDisabled = {this.state.disable_org}
                                                />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>Truck No</label>
                                                <input type="text" className="form-control" name="truck_no" id="truck_no" onChange={this.changeVehicleHandler} value={this.state.truck_no} size="10" onBlur={this.onBlurTruck.bind(this)} required />
                                                <span className={" f12 "+(this.state.errtruck)+(this.state.clr)} style = {{color:this.state.clr}}>{this.state.errtruckmsg}</span>
                                            </div>
                                            {/* <div className="form-group col-xl-3 col-lg-2">
                                                    <label>Transporter Code </label>
                                                    <Select
                                                        className="border-radius-0"
                                                        name="transporter_code"
                                                        isMulti={false}
                                                        id="transporter_code"
                                                        style={{ borderRadius: "0px" }}
                                                        value={this.state.transporter_code}
                                                        onChange={this.newFilter.bind(this, "transporter_code")}
                                                        options={this.state.filterTransportersCode}
                                                    />
                                                </div> */}
                                                <div className="form-group col-xl-3 col-lg-2">
                                                    <label>Transporter </label>
                                                    <Select
                                                        className="border-radius-0"
                                                        name="transporter"
                                                        isMulti={false}
                                                        id="transporter"
                                                        style={{ borderRadius: "0px" }}
                                                        value={this.state.transporter}
                                                        onChange={this.newFilter.bind(this, "transporter")}
                                                        options={this.state.filterTransporters}
                                                    />
                                                </div>
                                                <div className="form-group col-xl-3 col-lg-2">
                                                    <label>Device Id </label>
                                                    <Select
                                                        className="border-radius-0"
                                                        name="device_id"
                                                         isMulti={false}
                                                        id="device_id"
                                                        style={{ borderRadius: "0px" }}
                                                        value={this.state.device_id}
                                                        onChange={this.newFilter.bind(this, "device_id")}
                                                        options={this.state.devicesId}
                                                    />
                                                </div>
                                                {this.state.checkForDeviceId ?
                                                    <div className="form-group col-sm-3">
                                                    <label>Enter Device Id</label>
                                                    <input type="text" className="form-control" name="inputDeviceId" id="inputDeviceId" onChange={this.changeAlphaNumericHandler} value={this.state.inputDeviceId} />
                                                </div> : ""}

                                            
                                            {/* <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_DRIVER_CODE}</label>
                                                <input type="text" className="form-control" name="driver_code" id="driver_code" onChange={this.changeAlphaNumericHandler} value={this.state.driver_code}  />
                                            </div> */}
                                            </div>
                                            <div className="row">
                                            <div className="form-group col-sm-2">
                                                <label>{Constant.LABEL_DRIVER_NAME}</label>
                                                <input type="text" className="form-control" name="driver_name" id="driver_name" onChange={this.changeAlphaNumericHandler} value={this.state.driver_name}  />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>Driver Mobile Number</label>
                                                <input type="text" className="form-control" name="driver_mobile_number" id="driver_mobile_number" onChange={this.changeNumberHandler} value={this.state.driver_mobile_number}  />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-2">
										<label>Sim Tracking Service Provider</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="mobile_network"
											style={{ borderRadius: "0px" }}
											value={this.state.mobile_network}
											onChange={this.newFilter.bind(this, "mobile_network")}
											options={this.state.sim_tracking_service_provider}
                                            required ={true}
										/>
									</div>
                                    <div className="form-group col-xl-3 col-lg-2">
										<label>Tracking Type</label>
										<Select
											className="border-radius-0"
											isMulti={false}
											id="type_of_tracking"
											style={{ borderRadius: "0px" }}
											value={this.state.type_of_tracking}
											onChange={this.newFilter.bind(this, "type_of_tracking")}
											options={this.state.tracking_type}
                                            required ={true}
                                            onBlur={this.onBlurTracking.bind(this)}
										/>
									</div>
                                    {this.state.check ? 
                                    <div className="form-group col-xl-3 col-lg-2">
                                        <label>Gps Service Provider</label>
                                        <Select
											className="border-radius-0"
											isMulti={false}
											id="gpsServiceProvider"
                                            // placeholder="Gps Service Provider"
											style={{ borderRadius: "0px" }}
											value={this.state.gpsServiceProvider}
											onChange={this.newFilter.bind(this, "gpsServiceProvider")}
											options={this.state.gps_service_provider}
                                            required ={true}
										/>
                                        
                                    </div>:""}

                                            

                                            
                                        </div>
                                        
                                    </div>
                                </fieldset>
                                <fieldset className="row mt-40p">
                                    <legend className="f16 fbold">{Constant.FIELDSET_LABEL_SHIPMENT}:</legend>
                                    <div className="col-sm-12">
                                        <div className="row">
                                            <div className="form-group col-sm-3">
                                                <label>Consignment Code</label>
                                                <input type="text" className="form-control" name="consignment_code" id="consignment_code" onChange={this.changeAlphaNumericHandler} value={this.state.consignment_code} />
                                            </div>
                                            {/* <div className="form-group col-sm-3">
                                                <label>Invoice Number</label>
                                                <input type="text" className="form-control" name="invoice_no" id="invoice_no" onChange={this.changeAlphaNumericHandler} value={this.state.invoice_no}  />
                                            </div> */}
                                            {/* <div className="form-group col-sm-2">
                                                <label>Invoice Date</label>
                                                <input type="text" className="datetimepicker_date form-control" name="invoice_date" id="invoice_date" onChange={this.changeHandler} autoComplete='off'/>
                                            </div> */}
                                            <div className="form-group col-sm-2">
                                                <label>Consignment Date</label>
                                                <input type="text" className="datepicker form-control" name="shipment_date" id="shipment_date" onChange={this.changeHandler}  autoComplete='off' />
                                            </div>
                                            <div className="form-group col-sm-2">
                                                <label>Consignment Time</label>
                                                <input type="text" className="datetimepicker_time form-control" name="shipment_time" id="shipment_time" onChange={this.changeHandler}  />
                                            </div>
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Customer</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="consignee_code"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.consignee_code}
                                                    onChange={this.newFilter.bind(this, "consignee_code")}
                                                    options={this.state.customers}
                                                    required ={true}
                                                />
                                            </div>
                                            {/* {(localStorage.getItem("pc") !=undefined && localStorage.getItem("pc") !="" && localStorage.getItem("pc") !="undefined") ?"": */}
                                            <div className="form-group col-xl-3 col-lg-3">
                                                <label>Plant</label>
                                                <Select
                                                    className="border-radius-0"
                                                    isMulti={false}
                                                    id="consignor_code"
                                                    style={{ borderRadius: "0px" }}
                                                    value={this.state.consignor_code}
                                                    onChange={this.newFilter.bind(this, "consignor_code")}
                                                    options={this.state.plants_coordinates}
                                                    isDisabled = {this.state.disable_plant}
                                                    required ={true}
                                                />
                                            </div>
                                            {/* } */}
                                            {/* <div className="form-group col-sm-3">
                                                <label>OFS No</label>
                                                <input type="text" className="form-control" name="ofs_no" id="ofs_no" onChange={this.changeHandler} value={this.state.ofs_no}  />
                                            </div> */}
                                            <div className="form-group col-sm-2">
                                                <label>Permit Expiry Date</label>
                                                <input type="text" className="datetimepicker_date form-control" name="permit_expiry_date" id="permit_expiry_date" onChange={this.changeHandler} />
                                            </div>
                                            
                                            
                                        </div>
                                    </div>
                                </fieldset>
                            
                                <div className="row mt-40p">
                                    <div className="col-sm-12">
                                    
                                        <button type="submit" className="btn btn-success" style={{marginRight: "10px" , padding: `${this.state.padding}px`}} onMouseOver={this.handleMouseOver} onMouseOut={this.handleMouseOut}>Create Shipment</button>
                                        
                                    </div>
                                </div>
                                </form>
                                
                            

                            </div>
                        </div>
                    </div>
                </div>
                <div className={"overlay-part "+(this.state.overly)} onClick={this.onClickHideAll.bind(this)}></div>
                <div className={"slide-r "+(this.state.sliderBulkForceClose)} style={{overflow:"auto"}}>
                    <div className="slide-r-title">
                        <h4>
                           Bulk Upload
                            <span className="float-right closebtn" style={{marginRight:"25px"}} onClick={this.onClickHide.bind(this)} >X</span>
                        </h4>
                    </div>
                    <div className="slide-r-body" style={{position:"relative"}}>
                        <div className="row col-xl-12 col-lg-12 ml-0p n-p-0"></div><div className={"row"} style={{padding:'5px 0px', width: '100%', zIndex:'9999',}} >
						<form method="POST" className="theme-form col-xl-12 col-lg-12" onSubmit={this.formBulkForceCloseHandler.bind(this)}>
                            <div className="form-group mt-20p">
								<label className="">Organization</label> 
								<Select
                                    className="border-radius-0"
                                    name="organization"
                                    isMulti={false}
                                    id="organization"
                                    style={{ borderRadius: "0px",width:"200px" }}
                                    value={this.state.bulkorganization}
                                    onChange={this.newFilter.bind(this, "bulkorganization")}
                                    options={this.state.filterOrganization}
                                />
							</div>
							<div className="form-group mt-20p">
								<label className="">Upload File</label> 
								<input type="file" name="uploadFile" onChange={this.changeBulkForceFileHandler.bind(this)}  className="form-control" required  />
							</div>
							<div className="col-xl-12 col-lg-12 form-group">
								<button type="submit" className="btn btn-success">Submit</button>
							</div>
						</form>
						<div className="col-xl-12 col-lg-12 form-group">
								<a href={require("../../assets/json/sample_rail_bulk_force_close.csv")} target="_blank" className="btn btn-warning">Download Template</a>
							</div>
                            <div className="col-xl-12 col-lg-12 form-group">
                                <h4>Note: </h4>
                                <p>If Tracking Type is SIM , Enter <span>sim</span> in Gps Service Provider and Enter 2 in Tracking Type column</p>
                                <p>If Tracking Type is GPS , Enter 1 in Tracking Type column and Select enmovil OR non_enmovil in Gps Service Provider column</p>
                            </div>
                        </div>
					</div>
				</div>
				<Modal open={open} onClose={this.onCloseModal}>
	                <div className={""} style={{padding:'5px 0px', overflow: 'hidden',zIndex:'9999',}} >
						<form className="row theme-form" method="post" onSubmit={this.saveNewCustomerForm.bind(this)}>

                            <div className="form-group col-sm-4">
                                <label>Customer Code</label>
                                <input type="text"  value={this.state.newcustomer_code} className="form-control" name="newcustomer_code" id="newcustomer_code" onChange={this.changeAlphaNumericNOSpeacialHandler} required />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Customer Name</label>
                                <input type="text"  value={this.state.newcustomer_name} className="form-control" name="newcustomer_name" id="newcustomer_name" onChange={this.changeAlphaNumericHandler.bind(this)} required />
                            </div>
                            <div className="form-group col-sm-4">
                                <label>Customer City</label>
                                <input type="text"  value={this.state.newcustomer_city} className="form-control" name="newcustomer_city" id="newcustomer_city" onChange={this.changeAlphaNumericHandler} required />
                            </div>
                            <div className="form-group col-sm-6">
                                <label>Customer Latitude</label>
                                <input type="text"  value={this.state.newcustomer_latitude} className="form-control" name="newcustomer_latitude" id="newcustomer_latitude" onChange={this.changeCoords} required />
                            </div>
                            
                            <div className="form-group col-sm-6">
                                <label>Customer Longitude</label>
                                <input type="text"  value={this.state.newcustomer_longitude} className="form-control" name="newcustomer_longitude" id="newcustomer_longitude" onChange={this.changeCoords} required />
                            </div>
                            <div className="form-group col-sm-12">
                                <button type="submit" className="btn btn-success pull-right f12">Submit</button>
                            </div>
                        </form>
                    </div>
                </Modal>
            </div>
        );
    }
}
$( function() {
    $( ".datepicker" ).datepicker({
        dateFormat:"dd-mm-yy",
        // minDate:-2,
        maxDate: '+1Y' 
        // maxDate:0
    });
  } );

function loadDatePicker(){
    $( ".datepicker" ).datepicker();
}
$('body').on('focus',".datepicker", function(){

    if( $(this).hasClass('hasDatepicker') === false )  {
        $(this).datepicker();
    }

});



window.onload = function (){
	// $(".datepicker").datepicker({
    //     dateFormat: "dd-mm-yy",
    //     changeMonth: true,
    //     changeYear: true
	// });
	$('.datetimepicker_mask').datetimepicker({
		mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        minDate:1
	});

	$(".styles_modal__gNwvD").css({width:"450px"})
};
document.addEventListener('turbolinks:before-render', () => {
	loadDateTimeScript() 
  });

function loadDateTimeScript(){

	$('.datetimepicker_mask').datetimepicker({
		//mask:'39-19-9999 29:59',
		format:'d-m-Y H:i',
        minDate:0,
	});
	$('.datetimepicker_date').datetimepicker({
		//mask:'39-19-9999',
		format:'d-m-Y H:m:s',
		timepicker:true,
        // minDate:0,
        // maxDate: '+1Y' 
	});
	$('.datetimepicker_time').datetimepicker({
		//mask:'39-19-9999',
		format:'H:i',
        step:1,
		datepicker:false,
        // minDate:0
	});
    var index  =window.document.getElementsByTagName("script")[1]
    var script = window.document.createElement("script")
    script.src="https://www.jqueryscript.net/demo/Clean-jQuery-Date-Time-Picker-Plugin-datetimepicker/jquery.datetimepicker.js"
    script.async=true
    script.defer = true
    index.parentNode.insertBefore(script,index)
}



function NumericCellEditor() {
}

// gets called once before the renderer is used
NumericCellEditor.prototype.init = function (params) {
    // create the cell
    this.eInput = document.createElement('input');

    if (isCharNumeric(params.charPress)) {
        this.eInput.value = params.charPress;
    } else {
        if (params.value !== undefined && params.value !== null) {
            this.eInput.value = params.value;
        }
    }

    var that = this;
    this.eInput.addEventListener('keypress', function (event) {
        if (!isKeyPressedNumeric(event)) {
            that.eInput.focus();
            if (event.preventDefault) event.preventDefault();
        } else if (that.isKeyPressedNavigation(event)) {
            event.stopPropagation();
        }
    });

    // only start edit if key pressed is a number, not a letter
    var charPressIsNotANumber = params.charPress && ('1234567890'.indexOf(params.charPress) < 0);
    this.cancelBeforeStart = charPressIsNotANumber;
};

function getCharCodeFromEvent(event) {
    event = event || window.event;
    return (typeof event.which == "undefined") ? event.keyCode : event.which;
}

function isCharNumeric(charStr) {
    return !!/\d/.test(charStr);
}

function isCharDecimal(charStr) {
    return !!/\./.test(charStr);
}

function isKeyPressedNumeric(event) {
    const charCode = getCharCodeFromEvent(event);
    const charStr = String.fromCharCode(charCode);
    return isCharNumeric(charStr) || isCharDecimal(charStr);
}

NumericCellEditor.prototype.isKeyPressedNavigation = function (event) {
    return event.keyCode === 39
        || event.keyCode === 37;
};


// gets called once when grid ready to insert the element
NumericCellEditor.prototype.getGui = function () {
    return this.eInput;
};

// focus and select can be done after the gui is attached
NumericCellEditor.prototype.afterGuiAttached = function () {
    this.eInput.focus();
};

// returns the new value after editing
NumericCellEditor.prototype.isCancelBeforeStart = function () {
    return this.cancelBeforeStart;
};

// example - will reject the number if it contains the value 007
// - not very practical, but demonstrates the method.
NumericCellEditor.prototype.isCancelAfterEnd = function () {
    var value = this.getValue();
    return value.indexOf('007') >= 0;
};

// returns the new value after editing
NumericCellEditor.prototype.getValue = function () {
    return this.eInput.value;
};

// any cleanup we need to be done here
NumericCellEditor.prototype.destroy = function () {
    // but this example is simple, no cleanup, we could  even leave this method out as it's optional
};

// if true, then this editor will appear in a popup 
NumericCellEditor.prototype.isPopup = function () {
    // and we could leave this method out also, false is the default
    return false;
};

$(document).on("input", ".num-cls", function() {

     this.value = this.value.replace(/\D/g,'');
    
    })
    
    $(document).on("input", ".mobnum-cls", function() {
    
     this.value = this.value.replace(/^\d{11}$/g,'');
    
    })
    
    $(document).on("input", ".alfa-cls", function () {
    
     this.value = this.value.replace(/[^a-z\s]+$/gi, '');
    
     });
    
     $(document).on("input", ".dept-cls", function () {
    
     this.value = this.value.replace(/[^a-z-\s]/gi, '');
    
    });
    
     $(document).on("input", ".ringi-cls", function () {
    
     this.value = this.value.toUpperCase();
    
     this.value = this.value.replace(/[^\d[A-Z]-\s]/gi, '');
    
     });